@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  @apply border border-gray-400 rounded py-0.5 px-1 w-full;
}

section {
  @apply mb-4 p-4;
}

p {
  @apply mb-1;
}

h1 {
  @apply text-4xl font-bold mb-8;
}

h2 {
  @apply font-bold text-lg mb-1;
}

button {
  @apply border border-black bg-gray-100 rounded p-2 !bg-gray-100;
}

.doc-content {
  all: revert;
  margin: 0 auto;
  @apply pd:!px-6 pd:!py-12;
}

.doc-content span {
  @apply pd:!max-w-full pd:!h-auto;
}

.doc-content img {
  @apply pd:!h-auto;
}
